import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import kebabCase from '../utils/kebabCase';

export default function TagsPage() {
	return (
		<StaticQuery
			query={graphql`
				query TagsQueries {
					allMdx(limit: 1000) {
						group(field: frontmatter___tags) {
							fieldValue
							totalCount
						}
					}
				}
			`}
			render={({ allMdx: { group } }) => (
				<Layout>
					<Seo title="Category Tags" description="Category tags on different articles of beach wagons and carts" />
					<div className="o-wrapper" style={{ minHeight: 'calc(100vh - 250px)' }}>
						<div className="o-row-2p5">
							<h1 className="c-md-h1">All Tags</h1>
						</div>
						<div className="o-row-4">
							<ul className="c-md-list">
								{group.map((tag) => (
									<li key={tag.fieldValue}>
										<Link to={`/tags/${kebabCase(tag.fieldValue)}/`} className="c-md-a">
											{tag.fieldValue} ({tag.totalCount})
										</Link>
									</li>
								))}
							</ul>
						</div>
					</div>
				</Layout>
			)}
		/>
	);
}
